<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.82765 4.82765C3 6.65531 3 9.59687 3 15.48V16.52C3 22.4031 3 25.3447 4.82765 27.1723C6.65531 29 9.59687 29 15.48 29H16.52C22.4031 29 25.3447 29 27.1723 27.1723C29 25.3447 29 22.4031 29 16.52V15.48C29 9.59687 29 6.65531 27.1723 4.82765C25.3447 3 22.4031 3 16.52 3H15.48C9.59687 3 6.65531 3 4.82765 4.82765ZM7.40002 11C7.5405 17.746 10.9119 21.8 16.8226 21.8H17.1576V17.9405C19.3296 18.1567 20.9719 19.7459 21.6311 21.8H24.7C23.8572 18.7297 21.6419 17.0324 20.2587 16.3838C21.6419 15.5838 23.5869 13.6378 24.0516 11H21.2636C20.6585 13.1405 18.8649 15.0865 17.1576 15.2703V11H14.3697V18.4811C12.6408 18.0486 10.458 15.9514 10.3608 11H7.40002Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: "IVk",
    props: {
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
